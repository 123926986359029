.StepList {
  background-color: var(--primary-200);
  padding: clamp(24px, calc(32vw / 7.68), 38px) 0;

  header {
    max-width: calc(584rem / 16);
    margin: 0 auto clamp(28px, calc(48vw / 7.68), 48px);
    text-align: center;
    h2 {
      font-size: clamp(calc(24rem / 16), calc(36vw / 7.68), calc(36rem / 16));
      margin-bottom: clamp(16px, calc(20vw / 7.68), 20px);

      &:has(strong) {
        margin-bottom: clamp(16px, calc(28vw / 7.68), 36px);
      }
    }
  }

  .list {
    counter-reset: counter;
    display: grid;
    gap: clamp(20px, calc(32vw / 7.68), 32px);
    max-width: calc(794rem / 16);
    margin: 0 auto;
    .item {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: clamp(12px, calc(20vw / 7.68), 20px);
      counter-increment: counter;

      &:nth-child(3n-2) {
        &::before {
          background: var(--primary-300);
        }
      }
      &:nth-child(3n-1) {
        &::before {
          background: var(--primary-400);
        }
      }
      &:nth-child(3) {
        &::before {
          background: var(--primary-700);
          color: var(--primary-100);
        }
      }
      &::before {
        content: counter(counter);
        display: grid;
        place-items: center;
        width: clamp(48px, calc(60vw / 7.68), 60px);
        height: clamp(48px, calc(60vw / 7.68), 60px);
        border-radius: 50%;
        font-size: clamp(calc(32rem / 16), calc(48vw / 7.68), calc(48rem / 16));
        font-family: var(--font-city-streetwear);
        line-height: calc(clamp(48px, calc(60vw / 7.68), 60px) * 1.3);
      }
      h3 {
        font-size: clamp(calc(32rem / 16), calc(40vw / 7.68), calc(40rem / 16));
        margin: clamp(12px, calc(16vw / 7.68), 16px) 0 8px;
        font-family: var(--font-city-streetwear);
        line-height: 100%;
      }

      p {
        font-size: clamp(calc(14rem / 16), calc(18vw / 7.68), calc(18rem / 16));
      }
    }
  }

  &[data-image='true'] {
    padding: clamp(20px, calc(32vw / 7.68), 32px) 0 clamp(20px, calc(48vw / 7.68), 48px);

    .container {
      display: grid;
      grid-template-columns: 1fr max-content;
      gap: clamp(24px, calc(32vw / 7.68), 32px);
      align-items: start;

      img {
        width: 100%;
        max-height: 454px;
        object-fit: cover;
        border-radius: 4px;
        height: 100%;
      }

      .list {
        max-width: clamp(calc(608rem / 16), calc(608vw / 10.23), calc(687rem / 16));
      }

      @media (max-width: 1023px) {
        grid-template-columns: 1fr;

        img {
          order: 2;
          max-width: 608px;
          max-width: 560px;
          margin: 0 auto;
        }
      }
    }
  }
}
