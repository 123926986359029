.RelatedNotes {
  display: block;
  text-align: center;
  color: var(--primary-800, #53423c);
  font-size: clamp(calc(14rem / 16), calc(18vw / 7.68), calc(18rem / 16));
  font-style: italic;
  font-weight: 300;
  line-height: 150%;
  text-decoration-line: underline;
  margin-bottom: 12px;
  max-width: 174px;

  svg {
    text-align: center;
    margin: 0 auto 12px;
  }

  small {
    color: var(--primary-600, #9a827a);
  }
}
