.GridImageShowcase {
  max-width: 910px;
  margin: 0 auto;
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img {
      width: calc((100% - clamp(16px, calc(24vw / 7.68), 32px) * 2) / 3);
    }
    @media (max-width: 749px) {
      img {
        width: calc((100% - clamp(16px, calc(24vw / 7.68), 32px)) / 2);
      }
    }
    gap: clamp(16px, calc(24vw / 7.68), 32px);
    margin: clamp(32px, calc(48vw / 7.68), 80px) 0;
  }
  h2,
  .copy {
    text-align: center;
  }
  h2 {
    max-width: 792px;
    margin: 0 auto;
  }
  .copy {
    max-width: 588px;
    margin: 0 auto;
    .paragraph {
      margin-bottom: clamp(28px, calc(36vw / 7.68), 36px);
    }
    .cta {
      margin: 0 auto;
    }
    .ctaAnnotation {
      margin-top: 8px;
      font-size: calc(14rem / 16);
    }
  }
}

.ParallaxImageShowcase {
  padding: clamp(182px, calc(208vw / 7.68), 208px) 0px;
  display: flex;
  align-items: center;
  header {
    padding: clamp(32px, calc(48vw / 7.68), 64px) 0;
    max-width: calc(587rem / 16);
    margin: 0 auto;
    text-align: center;
    h2 {
      margin-bottom: clamp(16px, calc(28vw / 7.68), 36px);
    }
    .paragraph {
      p:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .cta {
      margin: clamp(16px, calc(28vw / 7.68), 36px) auto 0;
    }
    .ctaAnnotation {
      font-size: calc(14rem / 16);
      margin-top: 8px;
    }
  }
  position: relative;
  .images {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    .img {
      z-index: -1;
      position: absolute;
      height: auto;
      &:nth-child(1) {
        width: clamp(156px, calc(213vw / 7.68), 280px);
        left: 0;
        top: 30%;
      }
      &:nth-child(2) {
        width: clamp(70px, calc(134vw / 7.68), 174px);
        left: 31%;
        top: 10%;
      }
      &:nth-child(3) {
        width: clamp(156px, calc(213vw / 7.68), 278px);
        left: 77%;
        top: 0;
      }
      &:nth-child(4) {
        width: clamp(70px, calc(134vw / 7.68), 174px);
        left: 66%;
        bottom: 0;
      }
      @media (max-width: 1149px) {
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          left: 65%;
          top: 15%;
        }
        &:nth-child(3) {
          left: unset;
          top: unset;
          right: 0;
          bottom: 0;
        }
        &:nth-child(4) {
          left: 8%;
          bottom: 5%;
        }
      }
    }
  }
}
