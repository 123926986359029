.Hero {
  display: grid;
  gap: clamp(20px, calc(28vw / 7.68), 32px);
  grid-template-columns: 7fr 5fr;
  border-radius: 4px;

  @media (max-width: 999px) {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .imageWrapper {
    position: relative;
    display: flex;
    align-self: center;

    >img {
      aspect-ratio: 1.6/1;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 4px;
      display: block;
    }
  }

  .content {
    display: grid;
    align-content: space-between;
    gap: 16px;

    >div {
      display: grid;
      gap: 24px;

      .paragraph {
        display: grid;
        gap: 12px;
      }

      >h3 {
        font-size: clamp(calc(18rem / 16), calc(28vw / 7.68), calc(28rem / 16));
      }

      @media (max-width: 999px) {
        padding: 0px 20px;
      }
    }

    .author {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 16px;
      align-items: center;
      padding: 20px 20px 22px 20px;
      background: var(--primary-200);
      align-items: flex-start;

      >img {
        width: 60px;
        height: 60px;
      }
    }
  }
}