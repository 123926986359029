.ColorPicker {
  text-align: center;
  width: 250px;
  border: 1px solid var(--primary-300);
  padding: 8px;

  .list {
    .item {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 12px;
      padding: 8px;

      .colorName {
        justify-self: flex-start;
        font-style: italic;
        text-decoration-line: underline;
      }

      >p {
        margin-top: unset;
      }

      .circleWrapper {
        border: solid 1px var(--primary-300);
        border-radius: 50%;
        width: 28px;
        height: 28px;

        .circle {
          border: 6px solid;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          margin-top: unset;
        }
      }
    }
  }
}