.ImageBadge {
  display: flex;
  flex-direction: column;
  width: 50%;
  border-radius: 4px;
  position: relative;
  margin-top: clamp(48px, calc(64vw/7.68), 128px);

  @media (max-width: 499px) {
    width: 100%;
  }

  h2 {
    padding-left: 28px;
    position: absolute;
    top: 20px;
    left: -4px;
    width: 75%;
    border-radius: 4px 4px 0 0;
    padding-top: clamp(12px, calc(12vw/7.68), 20px);
    background: var(--primary-300);
    clip-path: polygon(100% 100%, 0 100%, 0 0, 100% 0, 90% 50%);
  }

  img {
    border-radius: 4px;
    height: auto;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
    display: block;
  }
}