.LessonHero {
  margin-top: 0 !important;

  .columns {
    display: flex;
    gap: clamp(36px, calc(48vw / 7.68), 80px);
    margin-top: clamp(36px, calc(48vw / 7.68), 80px);
    flex-wrap: wrap;

    .column {
      max-width: 285px;
      width: 100%;

      h2 {
        font-size: calc(24rem / 16);
        margin-bottom: 24px;
      }

      p {
        color: var(--primary-800, #53423c);
        font-size: calc(14rem / 16);
        font-weight: 300;
        line-height: 150%;
        margin-top: 8px;
      }

      label + label {
        margin-top: 20px;
      }

      .list {
        display: grid;
        gap: 16px;

        li {
          list-style: none;
          display: block;

          a {
            display: block;
            width: fit-content;
            font-size: calc(18rem / 16);

            small {
              color: var(--primary-600, #9a827a);
              font-size: calc(18rem / 16);
            }
          }
        }
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 380px;
    gap: clamp(12px, calc(24vw / 7.68), 32px);

    @media (max-width: 1080px) {
      grid-template-columns: 1fr;
    }

    .returnLink {
      grid-column: 1/-1;
      width: fit-content;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 4px;

      svg {
        transition: transform 0.3s var(--easing);
      }

      &:hover {
        svg {
          transform: translateX(-4px);
        }
      }
    }
  }

  .video {
    aspect-ratio: 16/9;
    background-color: var(--background-300);
    margin-bottom: clamp(16px, calc(16vw / 7.68), 24px);

    iframe {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
    }
  }

  .progress {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 10px;

    flex-wrap: wrap;

    h1 {
      color: var(--primary-800, #53423c);
      font-size: calc(28rem / 16);
      font-weight: 300;
      line-height: 150%;
    }

    p {
      color: var(--primary-800, #53423c);
      font-size: calc(18rem / 16);
      font-weight: 300;
      line-height: 150%;
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .chapter {
    margin-bottom: 24px;
  }

  .nav {
    display: grid;
    gap: 24px 16px;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 900px) {
      grid-template-columns: 1fr auto 1fr;
    }

    @media (max-width: 899px) {
      grid-template-columns: auto auto;

      button:nth-child(2) {
        order: 2;
        grid-column: 3/1;
        grid-row: 1;
        margin: 0 auto;
      }
    }

    .complete-button {
      display: flex;
      align-items: center;
      gap: 6px;

      span {
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 0.5px solid var(--primary-700, #766965);
        transition: all 0.3s var(--easing);

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          stroke-dasharray: 19;
          stroke-dashoffset: 19;
          transition: all 0.3s var(--easing);
        }
      }

      &[data-checked='true'] {
        span {
          border-color: #455b49;
          background: #b4c1b6;

          svg {
            stroke-dashoffset: 0;

            path {
              stroke: #455b49;
            }
          }
        }
      }
    }

    a.next {
      display: flex;
      align-items: center;
      gap: 4px;
      width: fit-content;
      margin-left: auto;

      svg {
        transition: transform 0.3s var(--easing);
      }

      &:hover {
        svg {
          transform: translateX(4px);
        }
      }
    }

    a.prev {
      display: flex;
      align-items: center;
      gap: 4px;
      width: fit-content;
      margin-right: auto;

      svg {
        transition: transform 0.3s var(--easing);
      }

      &:hover {
        svg {
          transform: translateX(-4px);
        }
      }
    }
  }

  .lessonsWrapper {
    position: relative;
    height: 300px;

    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 15px;
      background: linear-gradient(to top, var(--primary-100), transparent);
    }
  }

  .lessons {
    overflow-y: auto;
    max-height: 300px;
    padding-right: 16px;

    &::-webkit-scrollbar {
      width: 5px;
      height: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--primary-300);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--primary-600);
      border-radius: 2px;
    }

    a {
      margin-top: 4px;
      display: flex;
      gap: 16px;
      align-items: center;
      padding: 10px 8px;
      transition:
        background-color 0.3s var(--easing),
        color 0.3s var(--easing),
        font-size 0.3s var(--easing);

      color: var(--primary-700, #766965);
      font-size: 1rem;
      font-weight: 300;
      line-height: 27px;

      p {
        display: flex;
        gap: 4px;
        align-items: center;
      }

      small {
        color: var(--primary-500, #b4a29c);
        font-size: 1rem;
        font-weight: 300;
      }

      &[data-checked='true'] {
        background: #d0dad2;
      }

      &[data-current='true'] {
        background: var(--primary-200, #faf4f0);
        color: var(--primary-800, #53423c);
      }

      &:hover {
        background: var(--primary-200, #faf4f0);
        color: var(--primary-800, #53423c);
      }
    }
  }
}
