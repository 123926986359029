.BlogSection {
  display: grid;
  justify-items: center;
  gap: 64px;

  header {
    max-width: 584px;
    text-align: center;

    >h2 {
      margin-bottom: clamp(16px, calc(28vw / 7.68), 38px);
    }
  }

  .highlightedPost {
    display: grid;
    gap: clamp(20px, calc(28vw / 7.68), 32px);
    grid-template-columns: 7fr 5fr;
    border-radius: 4px;
    position: relative;
    background: var(--primary-200);

    &:hover .imageWrapper>img {
      transform: scale(1.05);
    }

    .link {
      position: absolute;
      inset: 0;
      z-index: 2;
      margin: 0 !important;
    }

    @media (max-width: 999px) {
      grid-template-columns: 1fr;
      width: 100%;
    }

    .imageWrapper {
      position: relative;
      display: flex;
      align-self: center;
      overflow: hidden;
      border-radius: 4px;

      >img {
        aspect-ratio: 1.6/1;
        object-fit: cover;
        width: 100%;
        height: auto;
        transition: transform 0.3s;
      }
    }

    .highlightedPostContent {
      display: grid;
      align-content: space-between;
      gap: 16px;

      >div {
        display: grid;
        gap: 24px;

        >h3 {
          font-size: clamp(calc(18rem / 16), calc(28vw / 7.68), calc(28rem / 16));
        }

        @media (max-width: 999px) {
          padding: 0px 20px;
        }
      }

      .author {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 16px;
        align-items: center;
        padding: 20px 20px 22px 20px;
        align-items: flex-start;

        >img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

  .blogPosts {
    display: grid;
    column-gap: 32px;
    row-gap: clamp(28px, calc(24vw / 7.68), 48px);
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;

    @media (max-width: 999px) {
      grid-template-columns: 1fr;
    }

    .item {
      display: grid;
      width: 100%;
      gap: 16px;
      align-items: flex-start;
      position: relative;

      a {
        position: absolute;
        z-index: 2;
        inset: 0;
      }

      .imageWrapper {
        position: relative;
        overflow: hidden;
        border-radius: 4px;

        >img {
          width: 100%;
          object-fit: cover;
          aspect-ratio: 381/239;
          transition: transform 0.3s;
        }
      }

      &:hover .imageWrapper>img {
        transform: scale(1.05);
      }

      @media (max-width: 999px) {
        grid-template-columns: 1fr 0.8fr;
      }

      @media (max-width: 499px) {
        grid-template-columns: 1fr;
      }

      >h3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      div {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: var(--primary-700);
        font-size: calc(14 / 16) rem;
      }



      >div {
        display: grid;
        gap: 16px;
      }
    }
  }
}