.PasswordInput {
  display: grid;
  gap: 8px;
  position: relative;

  .label-wrap {
    display: inline-flex;
    gap: 4px;

    .label {
      color: var(--primary-800);
      display: inline;
      font-size: calc(14rem/16);
      font-weight: 300;
    }

    .error {
      color: var(--error-800);
      display: inline-flex;
      align-items: center;
      gap: 2px;
      font-size: calc(14rem/16);
      font-weight: 300;
    }
  }

  input,
  textarea {
    font-size: calc(14rem/16);
    font-weight: 300;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--primary-400);
    background-color: transparent;
    color: var(--primary-500);
    transition:
      background-color 0.5s var(--easing),
      border 0.5s var(--easing),
      color 0.5s var(--easing);
    padding: 8px;

    &:hover {
      background-color: var(--primary-200);
      color: var(--primary-600);
    }

    &:focus,
    &:active {
      background-color: transparent;
      border: 1px solid var(--primary-600);
      color: var(--primary-900);
      outline: none;
    }
  }

  .input-wrap {
    position: relative;

    button {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      font-size: calc(14rem/16);
      font-weight: 300;

      &::before {
        content: '';
        position: absolute;
        left: -12px;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: var(--primary-400);
      }

      &::after {
        bottom: 1px;
      }
    }

    .input-info{
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(100%);
      font-size: calc(14rem/16);
      font-weight: 300;
      transition: color 0.3s var(--easing);

      &.success{
        color: var(--success-600);
        .progress::after{
          background-color: var(--success-600);
        }
      }

      &.errored{
        color: var(--error-600);
        .progress{
          background-color: var(--error-400);
        }
        .progress::after{
          background-color: var(--error-600);
        }
      }

      .progress{
        width: 80%;
        position: relative;
        height: 1px;
        background-color: var(--primary-400);
        display: block;
        margin-left: auto;
        margin-top: 8px;
        transition: all 0.3s var(--easing);

        &::after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: var(--progress);
          max-width: 100%;
          background-color: var(--primary-600);
          transition: all 0.3s var(--easing);
        }
      }

      &:has(.progress) {
      }
    }
  }

  &.errored {
    input,
    textarea {
      border: 1px solid var(--error-600);
    }
  }

  &.password{
    padding-bottom: 24px;
  }
}
