.ArticleGreetings {
  display: grid;
  gap: clamp(16px, calc(24vw/7.68), 32px);
  grid-template-columns: 3fr 5fr;
  grid-template-rows: auto auto;
  background: var(--primary-200);
  align-items: center;
  padding-right: clamp(16px, calc(24vw/7.68), 32px);
  border-radius: 4px;

  margin: clamp(64px, calc(80vw/7.68), 144px) 0px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 3fr;
  }

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: contain;
    grid-column: 1;
    grid-row: 1/-1;
    align-self: flex-start;

    @media (max-width: 599px) {
      grid-row: 1;
    }
  }

  .text {
    font-size: clamp(calc(16rem/16), calc(18vw/7.68), calc(28rem/16));
    padding-top: clamp(8px, calc(15vw/7.68), 32px);
    margin-top: unset;
  }

  .name {
    justify-self: flex-end;
    grid-column: 2;
    grid-row: 2;
    padding-bottom: clamp(8px, calc(15vw/7.68), 32px);
    margin-top: unset;
  }

}