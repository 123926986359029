.HeroPhysical {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media (max-width: 840px) {
    grid-template-columns: 1fr;
  }

  .add-to-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    .pay-po {
      color: var(--primary-700, #766965);
      font-size: calc(14rem / 16);
      font-weight: 300;
      line-height: 150%;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 16px;
    }
  }

  .rating {
    display: flex;
    gap: 4px;
    margin-bottom: 4px;

    span {
      font-size: calc(14rem/16);
      color: var(--primary-800, #53423c);

      b {
        font-weight: 300;
        font-size: calc(32rem/16);
        font-family: var(--font-city-streetwear);
      }
    }
  }

  .divider {
    height: 1px;
    background-color: var(--primary-400);
    width: 100%;
    margin-top: 16px;
    margin-bottom: 36px;
  }

  .annotations {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 0;

    p {
      margin: 0 20px;
      color: var(--primary-700);
    }
  }

  .attributes {
    margin-top: 36px;
    margin-bottom: 36px;

    display: grid;
    gap: 24px;

    .colorPicker {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      span {
        padding-right: 16px;
        align-self: center;
      }

      .item {
        padding-bottom: 8px;

        &:hover {
          cursor: pointer;
        }

        .circleWrapper {
          border: solid 1px var(--primary-300);
          border-radius: 50%;
          width: 28px;
          height: 28px;

          .circle {
            border: 6px solid;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            margin-top: unset;
          }
        }

        &[data-selected="true"] {
          border-bottom: 1px solid var(--primary-600);
        }
      }
    }

    >p {
      span {
        display: block;
        margin-bottom: 8px;
      }
    }
  }

  .price {
    >p {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--primary-800);
    }

    .discount {
      font-size: calc(14rem/16);
      text-decoration: line-through;
      color: var(--primary-600);
    }

    small {
      font-size: calc(14rem/16);
      line-height: 21px;
      font-weight: 300;
      color: var(--primary-700);
    }
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 64px;
    margin-bottom: 36px;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .calculator>p {
      color: var(--primary-600);
      font-size: calc(14rem/16);
      line-height: 21px;
      font-weight: 300;
    }

    .calculator>div {
      display: grid;
      gap: 8px;
      grid-template-columns: 43px auto 43px;
      grid-template-rows: 48px;
      margin-bottom: 8px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        transition: background-color .3s var(--easing);

        &:hover {
          background-color: var(--primary-200);
        }

        &:disabled {
          cursor: default;
          background-color: transparent;
        }
      }

      input {
        text-align: center;
        -moz-appearance: textfield;
        appearance: textfield;
        border: 1px solid var(--primary-300);
        border-radius: 4px;
        min-width: 43px;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }


  .info {
    h1 {
      margin-top: 16px;
    }
  }
}