.ProcessShowcase {
  display: grid;
  gap: clamp(48px, calc(48vw/7.68), 64px);
  counter-reset: counter;
  margin: clamp(16px, calc(32vw/7.68), 64px) 0px;

  .item {
    display: grid;
    gap: clamp(16px, calc(20vw/7.68), 28px);

    .heading {
      display: grid;
      gap: clamp(16px, calc(16vw/7.68), 12px);
      grid-template-columns: auto 1fr;
      align-items: center;

      &::before {
        counter-increment: counter;
        content: counter(counter);
        width: clamp(42px, calc(64vw/7.68), 80px);
        height: clamp(42px, calc(64vw/7.68), 80px);
        border-radius: 50%;
        background: var(--primary-200);
        color: var(--primary-700);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--font-city-streetwear);
        font-size: clamp(calc(32rem/16), calc(48vw/7.68), calc(48rem/16));
        font-weight: 400;
        line-height: clamp(32px, calc(42vw/7.68), 42px);
        padding-top: 12px;
      }
    }


    .process {
      display: grid;
      gap: clamp(8px, calc(12vw/7.68), 20px);

      .processItem {
        display: grid;
        grid-template-columns: 3fr 5fr;
        gap: clamp(24px, calc(24vw/7.68), 32px);
        align-items: center;

        @media (max-width: 599px) {
          grid-template-columns: 1fr;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 4px;
          min-width: 250px;
        }

        &[data-reversed='true'] {
          grid-template-columns: 5fr 3fr;

          @media (max-width: 599px) {
            grid-template-columns: 1fr;
          }

          img {
            order: 2;
          }
        }

        [data-fullwidth='true'] {
          grid-column: 1/-1;
        }
      }
    }
  }

  .item:nth-child(1n) {
    .heading::before {
      background: var(--primary-300);
    }
  }

  .item:nth-child(2n) {
    .heading::before {
      background: var(--primary-400);
    }
  }

  .item:nth-child(3n) {
    .heading::before {
      background: var(--primary-500);
    }
  }
}