.LatestBlogEntries {
  header {
    max-width: calc(584rem / 16);
    text-align: center;
    margin: 0 auto clamp(36px, calc(48vw / 7.68), 64px);

    h2 {
      margin-bottom: clamp(12px, calc(28vw / 7.68), 36px);
    }
  }

  .blogPosts {
    display: grid;
    column-gap: 32px;
    row-gap: clamp(28px, calc(24vw / 7.68), 48px);
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;

    @media (max-width: 999px) {
      grid-template-columns: 1fr;
    }

    .item {
      display: grid;
      width: 100%;
      gap: 16px;
      align-items: flex-start;
      position: relative;

      a {
        position: absolute;
        z-index: 2;
        inset: 0;
      }

      @media (max-width: 999px) {
        grid-template-columns: 1fr 0.8fr;
      }

      @media (max-width: 499px) {
        grid-template-columns: 1fr;
      }

      >h3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      div {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: var(--primary-700);
        font-size: calc(14 / 16) rem;
      }

      .imageWrapper {
        position: relative;
        overflow: hidden;
        border-radius: 4px;

        >img {
          width: 100%;
          height: auto;
          object-fit: cover;
          aspect-ratio: 16/9;
          transition: transform 0.3s;
        }
      }

      &:hover .imageWrapper>img {
        transform: scale(1.05);
      }

      >div {
        display: grid;
        gap: 16px;
      }
    }
  }

  a {
    margin: clamp(36px, calc(48vw / 7.68), 64px) auto 0;
  }
}