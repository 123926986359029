.Introduction {
  background-color: var(--primary-200);
  padding-bottom: clamp(32px, calc(48vw / 7.68), 64px);
  display: grid;
  align-items: flex-start;
  column-gap: clamp(48px, calc(82vw / 7.68), 82px);
  @media (min-width: 1050px) {
    grid-template-columns: 1.75fr 1fr;
    img {
      order: 1;
    }
    &[data-reversed='true'] {
      grid-template-columns: 1fr 1.75fr;
      img {
        order: -1;
      }
    }
  }
  header {
    padding-top: clamp(32px, calc(60vw / 7.68), 64px);
    h2 {
      margin-bottom: clamp(20px, calc(28vw / 7.68), 28px);
    }
    .paragraph {
      > *:not(:last-child) {
        margin-bottom: clamp(12px, calc(16vw / 7.68), 16px);
      }
    }
    .cta {
      margin-top: clamp(28px, calc(36vw / 7.68), 36px);
    }
  }
  margin-top: clamp(32px, calc(60vw / 7.68), 64px);
  img {
    margin-left: auto;
    width: 100%;
    height: auto;
    @media (max-width: 499px) {
      width: calc(100% - 64px);
      margin-left: auto !important;
      margin-right: auto !important;
    }
    max-width: 413px;
    border-radius: 4px;
    margin-top: calc(clamp(32px, calc(60vw / 7.68), 64px) * -1);
  }
  &[data-reversed='true'] {
    img {
      margin-left: unset;
      margin-right: auto;
    }
  }
}
