.TileList {
  .list {
    display: grid;
    @media (min-width: 900px){
      grid-template-columns: 1fr 1fr;
    }
    gap: clamp(12px, calc(20vw/7.68), 20px) 32px;
    margin: clamp(32px, calc(48vw/7.68), 64px) 0;
    list-style-type: none;
    li {
      border-radius: 6px;
      background-color: var(--primary-200);
      padding: clamp(16px, calc(24vw/7.68), 32px);
      h3 {
        font-size: clamp(calc(18rem/16), calc(24vw/7.68), calc(24rem/16));
        margin-bottom: clamp(8px, calc(12vw/7.68), 12px);
      }
      .description {
        font-size: clamp(calc(14rem/16), calc(18vw/7.68), calc(18rem/16));
      }
    }
  }
  h2,
  .copy {
    text-align: center;
  }
  .copy {
    max-width: 586px;
    margin: 0 auto;
    .paragraph {
      margin-bottom: clamp(28px, calc(36vw/7.68), 36px);
    }
    .cta {
      margin: 0 auto;
    }
    .ctaAnnotation {
      margin-top: 8px;
      font-size: calc(14rem/16);
    }
  }
  position: relative;
  .decoration {
    width: clamp(67px, calc(89vw/7.68), 118px);
    height: auto;
    transform: translateY(50%);
    left: 5%;
    @media (max-width: 699px){
      left: 0;
      transform: translateY(calc(100% + 8px));
    }
    position: absolute;
    bottom: 0;
  }
}