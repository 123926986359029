.chapterCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 36px;
  justify-content: flex-start;
  position: relative;
  opacity: 0.5;

  a {
    z-index: 3;
  }

  > a {
    position: absolute;
    inset: 0;
    display: block;
    z-index: 2;
  }

  &[data-soon='false'] {
    opacity: 1;
    &:hover img {
      transform: scale(1.05);
    }
  }

  .image-wrap {
    position: relative;
    overflow: hidden;

    .badge {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      padding: 8px 20px;
      border-radius: 0px 0px 4px 0px;
      background: var(--primary-300, #efe8e7);

      color: var(--primary-800, #53423c);
      font-size: clamp(calc(14rem / 16), calc(14vw / 7.68), calc(18rem / 16));
      font-weight: 300;
      line-height: 150%;
    }

    img {
      border-radius: 4px;
      aspect-ratio: 381/276;
      width: 100%;
      height: auto;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  }

  h2 {
    margin-top: clamp(16px, calc(20vw / 7.68), 24px);
    margin-bottom: clamp(4px, calc(8vw / 7.68), 8px);

    color: var(--primary-800, #53423c);
    font-size: calc(24rem / 16);
    font-weight: 300;
    line-height: 130%;

    span {
      color: var(--primary-700, #766965);
      font-size: clamp(calc(16rem / 16), calc(18vw / 7.68), calc(18rem / 16));
      font-weight: 300;
      line-height: 150%;
    }
  }

  p {
    color: var(--primary-800, #53423c);
    font-size: calc(14rem / 16);
    font-weight: 300;
    line-height: 150%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    span {
      display: flex;
      gap: 12px;
      align-items: center;
    }
  }
}
