.DiscountHero {
  position: relative;
  padding: clamp(28px, calc(64vw / 7.68), 96px) 0 clamp(28px, calc(64vw / 7.68), 120px);
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: calc(-1 * clamp(36px, 6.7708333333vw, 64px));
  min-height: clamp(400px, calc(500vw / 7.68), 600px);

  overflow: clip;

  img {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    header {
      max-width: 587px;

      .heading {
        font-size: clamp(calc(24rem / 16), calc(36vw / 7.68), calc(36rem / 16));
        line-height: 200%;
        font-weight: 300;
        margin-bottom: clamp(12px, calc(28vw / 7.68), 28px);
      }

      .paragraph {
        font-weight: 300;
        line-height: 150%;

        > p:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    padding-bottom: 340px;

    img {
      height: 100%;
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: none;
      height: auto;
    }
  }

  @media (max-width: 779px) {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: bottom left;
    }
  }

  @media (max-width: 590px) {
    img {
      transform: translateX(-3%);
      width: calc(100% + 20px);
    }
  }

  @media (max-width: 359px) {
    padding-bottom: 380px;
    img {
      transform: translateX(-10%);
      width: calc(100% + 40px);
    }
  }

  @media (min-width: 2000px) {
    img {
      transform: translateY(8%);
    }
  }
}
