.LogoSection {
  header {
    margin: 0 auto;
    max-width: calc(793rem / 16);
    text-align: center;
    display: grid;
    gap: 16px;

    >img {
      display: grid;
      justify-self: center;
      max-width: 240px;
      width: 100%;
      height: auto;
    }

    .paragraph {
      margin-top: clamp(0px, calc(12vw / 7.68), 20px);

      &>*:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}