.Reviews {
  h2 {
    max-width: 585px;
    margin: 0 auto 56px;
    text-align: center;
  }

  .grid {
    columns: 2;
    column-gap: 32px;

    > div {
      margin-top: 56px;
      break-inside: avoid;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  >button{
    margin: 36px auto 0;
  }
}

.Reviews-empty {
  text-align: center;

  h2 {
    max-width: 585px;
    margin: 0 auto 28px;
    text-align: center;
  }

  p {
    max-width: 585px;
    margin: 0 auto;

    &:nth-child(3) {
      margin-top: 20px;
    }
  }

  > button,
  > a {
    margin: 36px auto 0 auto;
  }
}
